
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class RecentActions extends Vue {
  protected recentActions: Array<any> = [];

  protected get groupedRecentActions() {
    // Group actions by `action_date`
    return this.recentActions.reduce(
      (grouped: Record<string, Array<any>>, action) => {
        const date = action.action_date;
        if (!grouped[date]) {
          grouped[date] = [];
        }
        grouped[date].push({
          ...action,
          // Add or adjust properties here as needed
          start_time: this.$moment(action.start_time).format("HH:mm"),
          end_time: this.$moment(action.end_time).format("HH:mm"),
        });
        return grouped;
      },
      {}
    );
  }

  created() {
    this.$crudService
      .findAll({ resource: "/plant/my/plant-detail/statistics/recent-actions" })
      .then((response) => {
        console.debug("Recent ACtions response", response);
        if (response.data) {
          this.recentActions = response.data;
        }
      });
  }
}
