
import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { AppStoreMixin } from "@/mixins/Stores/AppStoreMixin";

@Component({
  components: {},
})
export default class DashboardUserMessages extends mixins(AppStoreMixin) {
  protected loadMessages() {
    this.loadDashboardMessagesAction();
  }

  created() {
    this.loadMessages();
  }
}
