
import { Component, Vue } from "vue-property-decorator";
import { HomeDashboard } from "@/config/Modules";
import DashboardUserMessages from "@/components/dashboard/DashboardUserMessages.vue";
import DashboardStat from "@/components/dashboard/DashboardStat.vue";
import RecentActions from "@/components/dashboard/user_statistics/RecentActions.vue";

@Component({
  components: {
    RecentActions,
    DashboardStat: DashboardStat,
    DashboardUserMessages,
  },
})
export default class Dashboard extends Vue {
  protected dashboardData: any = null;

  // Define stat metadata as a constant array
  protected dashboardStats = [
    {
      labelId: "modules.dashboard.users_with_active_plants",
      property: "growers_with_active_plants_count",
    },
    {
      labelId: "modules.dashboard.active_plants",
      property: "active_plants_count",
    },
    {
      labelId: "modules.dashboard.archived_plants",
      property: "archived_plants_count",
    },
    {
      labelId: "modules.dashboard.tents",
      property: "tents_count",
    },
    {
      labelId: "modules.dashboard.used_water_last_seven_days_milliliters",
      property: "used_water_last_seven_days_milliliters",
      formatter: (value: number | undefined) =>
        value ? this.$n(value / 1000, "decimal") : "-",
    },
  ];

  protected get resource(): string {
    return "/" + HomeDashboard.resource;
  }

  created() {
    this.fetchDashboardData();
  }

  protected fetchDashboardData() {
    this.$apiService.get(this.resource).then((response: any) => {
      console.debug(
        "Dashoard.vue fetchDashbaordData:",
        response,
        response.data
      );
      this.dashboardData = response.data;
    });
  }
}
